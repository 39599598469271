<template>
  <v-container fluid pa-1>
    <v-row no-gutters>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card flat tile>
          <v-card-title class="secondary">
            <span>Customers</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchCustomer"
              append-icon="search"
              label="Search"
              solo
              flat
              hide-details
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="green" v-on="on" dark @click="addCustomer">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <span>Add customer</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="customers.length != 0"
              :headers="headers"
              :items="customers"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :search="searchCustomer"
              class="elevation-0"
              hide-default-footer
              @page-count="pageCount = $event"
              :hide-default-header="isCompact"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="!isCompact">
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      <a
                        class="blue--text"
                        @click="onclickCustomer(item)"
                      >{{ item.first_name }} {{ item.last_name }}</a>
                    </td>
                    <td class="text-md-center">{{ item.email }}</td>
                    <td class="text-md-center">{{ item.phone }}</td>
                    <td class="text-md-center">{{ item.printable_name }}</td>
                    <td class="justify-center layout px-0">
                      <v-btn icon class="mx-0" @click="onEditCustomer(item.id)">
                        <v-icon color="green darken-3">edit</v-icon>
                      </v-btn>
                      <v-btn icon class="mx-0" @click="toggleDeleteCustomerDialog(item)">
                        <v-icon color="green darken-3">delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="item in items" :key="item.name">
                    <td class="text-xs-center">
                      <a
                        class="blue--text"
                        @click="onclickCustomer(item)"
                      >{{ item.first_name }} {{ item.last_name }}</a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <div v-else>
              <p>No Customers found.</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-pagination
              v-if="customers.length >= 10 "
              class="mt-3"
              light
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="openDeleteCustomerDialog" persistent max-width="600">
      <v-card>
        <v-card-title
          class="headline"
        >Deleting the customer will remove all invoices associated. Are you sure you want to proceed further ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="onDeleteCustomer">Yes, Delete</v-btn>
          <v-btn color="green darken-1" text @click="openDeleteCustomerDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "customers-list",
  components: {},
  props: {
    isCompact: { type: Boolean }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      searchCustomer: "",
      openDeleteCustomerDialog: false,
      selectedCustomer: {},
      items: [],
      headers: [
        {
          text: "Name",
          align: "center",
          sortable: true,
          value: "first_name"
        },
        { text: "Email", value: "email", align: "center" },
        { text: "Phone", value: "phone", align: "center" },
        { text: "Printable Name", value: "printable_name", align: "center" },
        { text: "", value: "", sortable: false, align: "center" }
        // { text: "", value: "name", sortable: false }
      ]
    };
  },
  computed: {
    customers() {
      return this.$store.getters["MDMStore/getterCustomers"];
    }
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onclickCustomer(customer) {
      this.$router.replace({ path: "/customers/customer/" + customer.id });
    },
    addCustomer() {
      this.$router.push({ path: "/new/customer", props: { addOrEdit: "add" } });
    },
    onEditCustomer(custId) {
      this.$router.push({ path: "/edit/customer/" + custId });
    },
    toggleDeleteCustomerDialog(customer) {
      this.selectedCustomer = customer;
      this.openDeleteCustomerDialog = true;
    },
    onDeleteCustomer() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/deleteCustomer",
          custId: self.selectedCustomer.id
        })
        .then(result => {
          if (result.data.hasOwnProperty("success") && result.data.success) {
            self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
              text: "Customer deleted successfully",
              status: "success"
            });
            self.$store.dispatch({
              type: "MDMStore/getCustomers"
            });
          } else {
            self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
              text: "Error in deleting customer.",
              status: "warning"
            });
          }
          this.openDeleteCustomerDialog = false;
        });
    }
  }
};
</script>
