<template>
  <v-container fluid pa-1 fill-height>
   <v-row no-gutters class="fill-height">
     <v-col cols="12" sm="12" md="12" lg="12" class="fill-height">
      <v-card flat tile class="fill-height">
          <v-card-text>
             <v-data-table
             v-if="invoices.length !=0"
            :headers="headers"
            :items="invoices"
            hide-default-footer
            class="elevation-0  blue-grey lighten-5"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.invoice_no">
                  <td class="text-left">
                    <a @click="onClickInvoice(item)">{{ item.invoice_no }}</a>
                  </td>
                  <td class="text-left">
                    {{ item.hasOwnProperty('created_at') ? (item.created_at).split("T")[0] : "" }}
                  </td>
                  <td class="text-left">
                    {{ item.hasOwnProperty('period_from') ? (item.period_from).split("T")[0] : "" }}
                  </td>
                  <td class="text-left">
                    {{ item.hasOwnProperty('period_to') ? (item.period_to).split("T")[0] : "" }}
                  </td>
                  <td class="text-left">{{ (Number(item.amount)).toFixed(2) }}</td>
                  <td class="text-left">
                    <v-btn icon class="mx-0">
                      <v-icon color="green darken-3">vertical_align_bottom</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <span v-else>No Invoices found.</span>
          </v-card-text>
        </v-card>
     </v-col>
   </v-row>
  </v-container>
</template>

<script>
// import {
//     API
// } from "../../api.js";
export default {
  name: "customer-invoices-list",
  components: {},
   props:{
    invoices: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
         { text: "Invoice #", value: "invoice_no", align: "left" },
        {
          text: "Date Created",
          align: "left",
          sortable: false,
          value: "created_at"
        },
        { text: "Period From", value: "period_from", align: "left" },
        { text: "Period To", value: "period_to", align: "left" },
        // { text: "Tariff", value: "state", align: "center" },
        { text: "Amount", value: "amount", align: "left" },
         { text: "Actions", value: "", sortable: false, align: "left" }
      ]
    };
  },
  computed: {
  },
  beforeCreate() {},
  mounted() {
  },
  methods: {
    createNewInvoice(){
      this.$router.push({path:'/invoices/new'})
    },
    onClickInvoice(invoice){
       this.$router.push({path:'/invoices/invoice/'+invoice.id})
    }
  }
};
</script>
