<template>
  <v-container fluid py-1 pr-1 pl-0>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" class="fill-height">
        <v-card class="elevation-1 fill-height">
          <v-card-title class="text-center justify-left py-6">
            <h1 class="font-weight-light headline">{{customer.first_name}}</h1>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn icon class="ml-2" color="purple" dark @click="editCustomer(customer.id)">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn icon color="purple" @click="closeCustomerView">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-tabs v-model="tab" color="blue" slider-size="3">
            <v-tab>Overview</v-tab>
            <v-tab>Meters</v-tab>
            <v-tab>Invoices</v-tab>
            <!-- <v-tab>Meters</v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <customer-overview v-if="customer.hasOwnProperty('id')" :customer="customer"></customer-overview>
            </v-tab-item>
            <v-tab-item>
              <customer-meters-list
                v-if="customer.hasOwnProperty('meters')"
                :meters="customer.meters"
              ></customer-meters-list>
            </v-tab-item>
            <v-tab-item>
              <customer-invoices-list
                v-if="customer.hasOwnProperty('invoices')"
                :invoices="customer.invoices"
              ></customer-invoices-list>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerOverview from "./CustomerOverview";
import CustomerInvoicesList from "./CustomerInvoicesList";
import CustomerMetersList from "./CustomerMetersList";

export default {
  name: "customer-view",
  components: {
    CustomerOverview,
    CustomerInvoicesList,
    CustomerMetersList
  },
  data() {
    return {
      tab: null,
      customer: {}
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      var self = this;
      if (to.name == "viewcustomer") {
        self.$store
          .dispatch({
            type: "MDMStore/getCustomerInfo",
            custId: self.$router.currentRoute.params.custId
          })
          .then(data => {
            self.customer = Object.assign({}, data);
          });
      }
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store
      .dispatch({
        type: "MDMStore/getCustomerInfo",
        custId: this.$router.currentRoute.params.custId
      })
      .then(data => {
        this.customer = Object.assign({}, data);
      });
  },
  methods: {
    closeCustomerView() {
      this.$router.replace({ path: "/customers" });
    },
    editCustomer(id){
      this.$router.replace({ path: "/edit/customer/"+id});
    }
  }
};
</script>
