<template>
  <v-container fluid pa-0 fill-height>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" :sm="customerListFlex" :md="customerListFlex" :lg="customerListFlex">
        <customers-list :isCompact="customerListFlex == 3"></customers-list>
      </v-col>
      <v-col col="auto" md="9" sm="9" lg="9" v-if="customerListFlex == 3" class="fill-height">
        <customer-view></customer-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomersList from "../components/customers/CustomersList";
import CustomerView from "../components/customers/CustomerView";

export default {
  components: {
    CustomersList,
    CustomerView
  },
  data() {
    return {
      customerListFlex: 12
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.$store.dispatch({
      type: "MDMStore/getCustomers"
    });
      if (vm.$router.currentRoute.name == "viewcustomer") {
        vm.customerListFlex = 3;
      } else {
        vm.customerListFlex = 12;
      }
    });
  },
  watch: {
    $route(to, from) {
      if (to.name == "viewcustomer") {
        this.customerListFlex = 3;
      } else {
        this.customerListFlex = 12;
      }
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
      type: "MDMStore/getCustomers"
    });
  }
};
</script>
