<template>
  <v-container fluid pa-1 fill-height>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" sm="12" md="12" lg="12" class="fill-height">
        <v-card flat tile class="fill-height">
          <v-card-text>
            <v-data-table
            v-if="meters.length != 0"
              :headers="headers"
              :items="meters"
              hide-default-footer
              class="elevation-0  blue-grey lighten-5"
              single-select
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.serial_number">
                    <!-- <td class="text-md-center">
                    <v-icon v-if="item.isOffline == 'true'" color="red">highlight_off</v-icon>
                    <v-icon v-else color="green">check_circle_outline</v-icon>
                    </td>-->
                    <td class="text-left">
                       <v-icon v-if="item.isOffline == 'true'" color="red" >highlight_off</v-icon>
                      <v-icon v-else color="green">check_circle_outline</v-icon>
                   <a class="blue--text" @click="onMeterClick(item)"> {{ item.name }}</a>
                    </td>
                    <td class="text-left">{{ item.serial_number }}</td>
                    <td class="text-left">{{ item.meterkind.kind_name}}</td>
                    <td class="text-left">{{ item.gateway.name }}</td>
                  </tr>
                </tbody>
                <!-- <tbody v-else>
                <tr v-for="item in items" :key="item.name">
                  <td class="text-xs-center">
                    <a @click="onclickCustomer(item)">{{ item.name }}</a>
                  </td>
                </tr>
                </tbody>-->
              </template>
            </v-data-table>
            <span v-else>  No customer meters found</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {
//     API
// } from "../../api.js";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "customer-meters-list",
  components: {},
  props:{
    meters: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name"
        },
        { text: "Serial No", value: "serial_number", align: "left" },
        { text: "Type", align: "left" },
        { text: "Gateway", value: "gateway", align: "left" },
      ],
    };
  },
  computed: {
  },
  beforeCreate() {},
  mounted() {
  },
  methods: {
    onMeterClick(meter){
      this.$store.commit(
          "MDMStore/SET_SELECTED_METER",
          meter.serial_number
        );
      this.$router.push({ path: "/analytics" });
      EventBus.$emit("select-analytics-meter", meter.serial_number);
    }
  }
};
</script>
