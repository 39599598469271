<template>
  <v-container fluid pa-3>
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" class="pa-3 ">
        <v-row no-gutters>
          <v-col cols="12" class="align-center">
            <span class="headline">{{customer.salutation }}</span>
            <span class="ml-1 headline">{{customer.first_name }}</span>
            <span class="ml-1 headline">{{customer.last_name }}</span>
          </v-col>
          <v-col cols="12">
            <span class="subtitle-2">{{customer.email }}</span>
          </v-col>
          <v-col cols="12">
            <span class="subtitle-2">{{customer.phone }}</span>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <span class="subtitle-1">Address</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="mt-2">
          <v-col cols="12" class="pa-1 subtitle-2">
            <span>{{customer.customer_address.address }}</span>
          </v-col>
          <v-col cols="12" class="pa-1 subtitle-2">
            <span>{{customer.customer_address.city}}, {{customer.customer_address.state}}</span>
          </v-col>
          <v-col cols="12" class="pa-1 subtitle-2">
            <span>{{customer.customer_address.country}}, {{customer.customer_address.postal_code}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <span class="subtitle-1">Billing Address</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="mt-2">
          <v-col cols="12" class="pa-1 subtitle-2">
            <span>{{customer.billing_address.address }}</span>
          </v-col>
          <v-col cols="12" class="pa-1 subtitle-2">
            <span>{{customer.billing_address.city}}, {{customer.billing_address.state}}</span>
          </v-col>
          <v-col cols="12" class="pa-1 subtitle-2">
            <span>{{customer.billing_address.country}}, {{customer.billing_address.postal_code}}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical class="mx-2"></v-divider>
      <v-col cols="12" sm="7" md="7" lg="7">
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="5" lg="5" class="text-center">
            <p class="subtitle-1">Total Amount</p>
            <p class="display-1 primary--text">$ {{totalAmount}}</p>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-col cols="12" sm="2" md="2" lg="2" class="text-center">
            <p class="subtitle-1"># meters</p>
            <p class="display-1 primary--text">{{customer.meters.length}}</p>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-col cols="12" sm="2" md="2" lg="2" class="text-center">
            <p class="subtitle-1"># Invoices</p>
            <p class="display-1 primary--text">{{customer.invoices.length}}</p>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row no-gutters class="mt-4" >
          <v-col cols="12" sm="12" md="12" lg="12" >
            <span class="heading">Invoices - </span>
            <span class="heading">Monthly amount for the past 12 months</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4 pa-4" >
           <v-col cols="12" sm="12" md="12" lg="12"  class="pa-4" v-if="customer.invoices.length != 0 && invoiceAmountData!= null">
            <bar-chart
                  :height="chartHeight"
                  :chart-data="invoiceAmountData"
                  :options="options"
            ></bar-chart>
          </v-col>
           <v-col cols="12" sm="12" md="12" lg="12"  class="pa-4" v-else>
            <span>No invoices found.</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
         
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { API } from "../../api.js";
import BarChart from "@/lib/BarChart.js";
export default {
  name: "customer-overview",
  props:{
    customer: {
      type: Object
    }
  },
  components: {
    BarChart
  },
  data() {
    return {
      invoiceAmountData: null,
      chartHeight:200,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, values) {
                        return '$' + value/1000 + ' K';
                    }
              }
            }
          ]
        },
        legend: {
          display:false,
          labels: {
            fontSize: 16,
            fontColor: "black",
            boxWidth: 20,
            fontFamily: "lato"
          }
        }
      }
    };
  },
  computed: {
    totalAmount() {
      return this.customer.invoices.reduce(function (accumulator, currentValue) {
                return accumulator + Number(currentValue.amount);
            }, 0).toFixed(2)
    },
  },
  beforeCreate() {},
  mounted() {
    this.getGraphData();
  },
  methods: {
    closeCustomerView() {
      this.$store.commit("MDMStore/SET_SELECTED_CUSTOMER", null);
    },
    async getGraphData() {
      var self = this;
      var graphData = await this.$store.dispatch({
        type: "MDMStore/getCustomerInvoiceInfo",
        custId: self.$router.currentRoute.params.custId
      });
      var labelArray = graphData.map(point => point["month"]);
      var dataArray = graphData.map(point => point["amount"]);
      this.invoiceAmountData={
        labels: labelArray,
            datasets: [{
               data: dataArray,
               backgroundColor:"#66BB6A"
            }]
              };
    }
  }
};
</script>
